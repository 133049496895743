var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('carousel',{attrs:{"autoplay":true,"loop":true,"autoplayLoop":true,"autoplayTimeout":3000,"autoplayHoverPause":true,"navigationEnabled":true,"paginationEnabled":false,"perPageCustom":[
      [0, 1],
      [450, 1],
      [680, 1],
      [940,2],
      [1100, 3],
      [1200, 4],
    ]}},_vm._l((_vm.courses),function(course){return _c('slide',{key:course.id},[_c('Card',{attrs:{"course":course,"cardType":_vm.cardType}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }